ul {
  margin: 0;
  padding: 0 0 56px 0;
  list-style: none;
}

.center {
  display: grid;
  place-items: center;
}
