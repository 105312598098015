form {
  width: 90%;
  max-width: 300px;
  background-color: #fff;
  padding: 33px 40px;
  margin: 5px;
  display: flex;
  align-content: center;
  flex-direction: column;
}

label {
  text-align: center;
  font-weight: bold;
  font-size: #1e1e1f;
  margin-bottom: 26px;
}

textarea {
  background-color: #f9fbfc;
  border-radius: 2px;
  box-shadow: 0px 5px 50px rgba(32, 35, 41, 0.25);
  color: #1e1e1f;
  font-size: 20px;
  text-align: center;
  padding: 12px;
  height: 96px;
  resize: none;
}

textarea::placeholder {
  color: #a5a5a5;
  font-family: var(--ff-primary);
  font-weight: 400;
}

textarea:focus {
  outline-color: var(--color-primary);
}

.TodoForm-buttonContainer {
  margin-top: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.TodoForm-button {
  cursor: pointer;
  display: inline-block;
  font-size: 2rem;
  color: #202329;
  font-weight: 400;
  width: 120px;
  height: 48px;
  border-radius: 2px;
  border: none;
  font-family: var(--ff-primary);
}

.TodoForm-button-add {
  background-color: var(--color-primary);
  box-shadow: 0px 5px 25px rgba(97, 218, 250, 0.5);
}

.TodoForm-button-add:hover {
  opacity: 0.8;
}

.TodoForm-button-cancel {
  background: transparent;
}

.TodoForm-button-cancel:hover {
  background-color: #1e1e1f;
  color: white;
}
